<template>
    <div class="side">
        <div class="side-nav-header">
            <div>
                <div class="side-avatar">
                    <el-avatar :size="75" :src="userInfo.avatar"></el-avatar>
                </div>
                <div class="side-username">{{ userInfo.username }}</div>
            </div>
        </div>
        <div class="side-nav">
            <div class="side-nav-item" v-for="(item, index) in navList" :key="index"
                :class="{ active: $router.currentRoute.name === item.pathName }" @click="$router.push(item.pathName)">
                <icon-svg :iconClass="item.icon"></icon-svg>
                {{ item.name }}<span class="el-badge__content" v-if="item.num > 0">{{ item.num }}</span>
            </div>
            <div class="side-nav-item" @click="confirmLogOut()">
                <icon-svg iconClass="#icon-a-zu12806"></icon-svg>
                退出登录
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
export default {
    name: 'sideNav',
    data () {
        return {
            circleUrl: require('@/assets/img/3ea6beec64369c2642b92c6726f1epng.jpg'),
            navList: [
                {
                    icon: '#icon-a-zu234',
                    name: '基本信息',
                    num: 0,
                    pathName: 'profile'
                },
                {
                    icon: '#icon-a-zu266',
                    name: '我的发布',
                    num: 0,
                    pathName: 'publish'
                },
                {
                    icon: '#icon-a-zu274',
                    name: '我的信息',
                    num: this.userInfo?.read_num || 0,
                    pathName: 'message'
                },
                {
                    icon: '#icon-a-zu12807',
                    name: '我的订单',
                    num: 0,
                    pathName: 'orders'
                },
                {
                    icon: '#icon-a-zu12807',
                    name: '我的地址',
                    num: 0,
                    pathName: 'address'
                }
            ]
        }
    },
    computed: {
        ...mapGetters(['isLogin', 'userInfo'])
    },
    created () {
        // 当前路由的名称
        //console.log(this.$router.currentRoute.name)
    },
    methods: {
        ...mapActions(["logout"]),
        confirmLogOut () {
            let that = this
            that.$confirm('确认退出登录吗？')
                .then(_ => {
                    that.logout()
                })

        }
    },
}
</script>

<style scoped lang="less">
.side {
    width: 200px;
    background-color: #FCFCFC;
    .side-nav-header {
        width: 100%;
        height: 227px;
        background-image: url(../../../assets/img/5b5cc1e26e9da18867ded2f80c40b2e8fb9909b025.png);
        background-position: center bottom;
        background-size: cover;
        display: grid;
        align-items: flex-start;
        justify-content: center;
        text-align: center;

        .side-avatar {
            margin-top: 30px;
        }

        .side-username {
            color: #333;
            font-size: 16px;
        }
    }

    .side-nav {
        padding: 30px;

        .side-nav-item {
            color: #2E3033;
            font-size: 16px;
            cursor: pointer;
            padding: 15px 0;
            transition: all 0.2s ease-in-out;

            &.active,
            &:hover {
                color: #EF312A;
            }

            .iconfont {
                font-size: 20px;
                margin-right: 10px;
            }
        }
    }
}
</style>