<template>
    <div>
        <headers />
        <div class="container">
            <div>
                <div class="userCenterWarp">
                    <div class="userCenterTitle">个人中心</div>
                    <el-row :gutter="30">
                        <el-col :span="4">
                            <sideNav />
                        </el-col>
                        <el-col :span="20" class="mt-30">
                            <div style="display: flex;justify-content: space-between;align-items: flex-start;">
                                <el-breadcrumb separator-class="el-icon-arrow-right" class="user-breadcrumb">
                                    <el-breadcrumb-item><strong>我的地址</strong></el-breadcrumb-item>
                                </el-breadcrumb>
                                <el-button size="mini" @click="$refs.addrDialog.showDialog('add', {})">添加地址</el-button>
                            </div>
                            <el-table :data="tableData" style="width: 100%">
                                <el-table-column prop="pic" label="封面图片" width="80">
                                    <template slot-scope="scope">
                                        <img :src="scope.row.pic" width="60px" height="60px" />
                                    </template>
                                </el-table-column>
                                <el-table-column prop="title" label="标题">
                                </el-table-column>
                                <el-table-column prop="hits" label="浏览次数" width="100" align="center">
                                </el-table-column>

                                <el-table-column prop="date" label="发布时间" width="180">
                                </el-table-column>

                                <el-table-column prop="status" label="发布状态" width="100" align="center">
                                    <template slot-scope="scope">
                                        <el-link :underline="false" type="success"
                                            v-if="scope.row.status === 1">已通过</el-link>
                                        <el-link :underline="false" type="info"
                                            v-if="scope.row.status === 0">待审核</el-link>
                                        <el-link :underline="false" type="danger"
                                            v-if="scope.row.status === 2">已删除</el-link>
                                    </template>
                                </el-table-column>

                                <el-table-column fixed="right" label="操作" width="100" align="center">
                                    <template slot-scope="scope">
                                        <el-link type="danger" :underline="false"
                                            @click="handleClick(scope.row)">删除</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="text-center p-30">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                    :current-page="currentPage" :page-sizes="[100, 200, 300, 400]" :page-size="100"
                                    layout="total, sizes, prev, pager, next, jumper" :total="400">
                                </el-pagination>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <!-- 添加地址弹窗 -->
        <addrDialog ref="addrDialog" @refresh="refresh" />
    </div>
</template>

<script>
import headers from '@/components/header.vue'
import sideNav from './components/sideNav.vue'
import addrDialog from '../products/components/addrDialog.vue'
import { getAddressList } from '@/api/apis'
export default {
    name: 'profile',
    components: {
        headers,
        sideNav,
        addrDialog
    },
    data () {
        return {
            currentPage: 1,
            tableData: []
        }
    },
    methods: {
        handleClick (row) {
            console.log(row)
        },
        handleSizeChange (val) {
            console.log(`每页 ${val} 条`)
        },
        handleCurrentChange (val) {
            console.log(`当前页: ${val}`)
        },
        getList(){
            getAddressList().then(res => {
                if (res.code === 1) {
                    this.tableData = res.data.list
                }
            })
        },
        refresh(){
            this.getList();
        }
    },
}
</script>

<style scoped lang="less">
.header {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 30px;
}

/deep/.el-table__header {
    th.el-table__cell {
        background-color: #FBFBFB;
    }

}
</style>